import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Auth } from "aws-amplify";
import {
    getStaffList,
    UpdateParticipantAttendance
} from "../../../services/AWS/competitionInformation/competitionInfo";
import Button from "@mui/material/Button";
import { generateCSV, UpdateType } from "../../../ultis/masterData";

const StaffListPage = () => {
    const [list, setItems] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [nameFilter, setNameFilter] = useState('');

    const getStaffListFromAPI = async () => {
        const userAuth = await Auth.currentSession();
        const staffList = await getStaffList("RubiksWCAAsianChampionship2024", userAuth.idToken.jwtToken, "staff");
        const rawJson = staffList.list;
        setItems(rawJson);
        setFilteredList(rawJson);
    }

    useEffect(() => {
        getStaffListFromAPI();
    }, []);

    // Filter list when nameFilter changes
    useEffect(() => {
        const filtered = list.filter(item =>
            item.fullName.toLowerCase().includes(nameFilter.toLowerCase())
        );
        setFilteredList(filtered);
    }, [nameFilter, list]);

    const handleStaffCheckIn = async (staffData) => {
        const userAuth = await Auth.currentSession();
        setIsGridLoading(true);
        await UpdateParticipantAttendance(
            staffData.ID,
            "RubiksWCAAsianChampionship2024",
            !staffData.attended,
            UpdateType.staff,
            userAuth.idToken.jwtToken
        );
        setIsGridLoading(false);
        window.location.reload();
    };

    const columns = [
        { field: 'fullName', headerName: 'Name', width: 200 },
        { field: 'attended', headerName: 'Taken', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleStaffCheckIn(params.row)}
                >
                    {params.row.attended === true ? "Undo" : "Attend"}
                </Button>
            ),
        },
        { field: 'TshirtSize', headerName: 'T-Shirt Size', width: 120 },
        { field: 'role', headerName: 'Role', width: 150 },
        { field: '30th', headerName: '30th', width: 120 },
        { field: '31th', headerName: '31th', width: 120 },
        { field: '1st', headerName: '1st', width: 120 },
        { field: '2nd', headerName: '2nd', width: 120 },
        { field: '3rd', headerName: '3rd', width: 120 },
        { field: '4th', headerName: '4th', width: 120 },
        { field: '5th', headerName: '5th', width: 120 },
    ];

    const columnsNameString = [
        "fullName",
        "attended",
        "TshirtSize",
        "role",
        "30th",
        "31th",
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
    ];

    const handleGenerateParticipantListClick = async () => {
        const participantCsv = await generateCSV(list, columnsNameString);
        await downloadFile(participantCsv, `AC2024_Staff_Data`, "text/csv");
    }

    const downloadFile = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl);
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">AC 2024 Staff List</h1>

            <div className="mb-4">
                <input
                    style={{fontSize:"20px",height:"30px"}}
                    type="text"
                    placeholder="Filter by name..."
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className="p-4 border rounded text-xl h-16 w-96 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
            </div>

            <DataGrid
                className="w-[80vw] h-[80vh] mb-4"
                columns={columns}

                getRowId={(row) => row.ID}
                pageSize={100}
                rows={filteredList}
                loading={isGridLoading}
            />

            <Button
                variant="contained"
                color="secondary"
                onClick={handleGenerateParticipantListClick}
            >
                Generate Full Staff List
            </Button>
        </div>
    );
}

export default StaffListPage;