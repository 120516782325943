import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import './style.css';
import { MastereventList, saveSelectedEventsToString } from "../../ultis/masterData";
import { useDispatch } from "react-redux";
import { getParticipantInfo, updateParticipantInfo, getEventDetailsGeneral } from "../../services/AWS/competitionInformation/competitionInfo";
import DatePicker from 'react-date-picker';
const EditParticipantForm = () =>{
    const {id} = useParams();
    const [participant, setParticipant] = useState({});
  const [errorMessages, setErrorMessages] = useState({});
  const [eventList, setEventList] = useState([]);
  const dispatch = useDispatch();
  const handleEventsCheckboxChange = (event) => {
    setEventToParticipate((prevData) => ({
      ...prevData,
      [event.target.name]: !EventToParticipate[event.target.name]
    }));


  }
  const BaseeventList = MastereventList;
  //map eventList to InitalMap with false value
  let InitialListMap = {}
  for (let i = 0; i < BaseeventList.length; i++) {
    InitialListMap[BaseeventList[i]] =  false;
  }
  
  const [country, setCountry] = useState("");
  const [EventToParticipate, setEventToParticipate] = useState(InitialListMap);

  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );
  const userAuthtoken = localStorage.getItem('MYCAAdminToken');

    const handleCountryChange = (event) =>{
        setCountry(event);
        }
    const handleChange = (event) => {
    
        const name = event.target.name;
        const value = event.target.value;
      setParticipant(values => ({...values, [name]: value}))
  }
  
  const getParticipantInfoAction = async (id) => {
    dispatch({ type: 'loading' });
    const compData = await getParticipantInfo(id, userAuthtoken);
    const compDetails = await getEventDetailsGeneral(compData.competition_ID)
    setParticipant(compData);
    setCountry(compData.country);
    setEventList(compDetails.Events.split(","));
    setEventToParticipate(ConvertEventStringToObj(compData.events, EventToParticipate));
    dispatch({ type: 'loaded' });
  }
  useEffect(() => {
    getParticipantInfoAction(id, userAuthtoken);
  }, []);
  const validateSelectedEvent = (EventToParticipate) => {
    let haveEvent = false;


    for (const entry of eventList) {
      let key = entry;
      let value = EventToParticipate[entry];
      if (value == true) {
        haveEvent = true;
      }

    }
    if (!haveEvent) {
      setErrorMessages({ name: "General", message: "Please select at least one event" });
      return false;
    }
    return true;
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({ type: 'loading' });
    const validateInputResult = await validateInput(participant);
 
    let dataToSubmit = participant;
    dataToSubmit.country = country;
    const validateEventResult = await validateSelectedEvent(EventToParticipate);

    if (!validateInputResult || !validateEventResult) {
      return;
    }
    if (dataToSubmit.remark == undefined) {
        dataToSubmit.remark = "";
    }
    dataToSubmit.events = await saveSelectedEventsToString(EventToParticipate);
    const APIRespond = await updateParticipantInfo(dataToSubmit, userAuthtoken);
    if (APIRespond == "success!") {

      setErrorMessages({ name: "General", message: "success! You may close the window now" });
      console.log("success");
    }
    else {
      setErrorMessages({ name: "General", message: APIRespond.data });

    }
 dispatch({ type: 'loaded' });


  }
  const validateInput = (data) => {
    if (!data.UserName) {
      setErrorMessages({ name: "UserName", message: "Full Name is required" });
      return false;
    }
    if (!data.gender) {
      setErrorMessages({ name: "gender", message: "gender is required" });
      return false;
    }
    if (!data.email) {
      setErrorMessages({ name: "email", message: "email is required" });
      return false;
    }
    if (!data.country) {
      setErrorMessages({ name: "country", message: "country is required" });
      return false;
    }
    if (!data.dob) {
      setErrorMessages({ name: "dob", message: "dob is required" });
      return false;
    }
    return true;
  };

  
    return (
      <div className="register-form">

      <form onSubmit={handleSubmit}>
          <div className="input-container">
            <label>Competition name: {participant.competition_ID}</label>
          <label>Full Name* </label>
            <input type="text" name="UserName" value={participant.UserName} required onChange={handleChange}/>
            {renderErrorMessage("UserName")}
        </div>
        <div className="input-container">
        <label>Nationality* </label>
        <CountryDropdown
          value={country}
          onChange={handleCountryChange} />
        </div>
        <div className="input-container">
        <label>Gender* </label>
            <select value={participant.gender}  onChange={handleChange} name="gender">
        <option value= {null}>-</option>
<option value="m">Male</option>

<option value="f">Female</option>

<option value="other">Other</option>
</select>
            {renderErrorMessage("gender")}
        </div>
        <div className="input-container">
          <label>Email* </label>
            <input type="text" name="email" value={participant.email} required onChange={handleChange}/>
            {renderErrorMessage("email")}
        </div>
        <div className="input-container">
        <label>Birth Date(YYYY-MM-DD)* </label>
            <input type="text" name="dob" value={participant.dob}  required onChange={handleChange}/>
            {renderErrorMessage("dob")}
        </div>
        <div className="input-container">
          <label>remark </label>
          <input type="text" name="remark"
                 value={participant.remark}  onChange={handleChange}/>
          {renderErrorMessage("dob")}
        </div>
        <div className="input-container">
          <label>WCA ID (If Available) </label>
            <input type="text" name="WCAID" value={participant.WCAID} onChange={handleChange} />
            </div>
          <div className="input-container">
            <label >
              Events:      </label>
            {eventList.map((item) => (
              <EventListSelection event={item} EventToParticipate={EventToParticipate} handleEventsCheckboxChange={(event) => { handleEventsCheckboxChange(event) }} />
            ))}
          </div >

        <div className="button-container">
          <input type="submit" />
          </div>
          <div>
            {renderErrorMessage("General")}
          </div>

      </form>
        </div>
    );
}
const EventListSelection = (props) => {
  //
  return (
        <div>
          <input style={/*make the checkbox button bigger */{}} type="checkbox" name={props.event} checked={props.EventToParticipate[props.event]} value={props.EventToParticipate[props.event]} onChange={props.handleEventsCheckboxChange} />
          {props.event}
        </div>

  )
}
const ConvertEventStringToObj = (eventString, InitialObject) => { 
  let eventObj = InitialObject;
  let eventArray = eventString.split(",");
  eventArray.forEach(event => {
    eventObj[event] = true;
  })
  return eventObj;
}




export default EditParticipantForm;