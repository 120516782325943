import "./loadingStyle.css";
import LoadingScreen from "react-loading-screen";
import React, { useState,useEffect } from "react";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

   const LoadingOverlay =(props)=> {

    if (props.isLoading){
        return (
            <div className="App" style={{minHeight:"200vh"}}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={true}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
          );
    }

}
export default LoadingOverlay;

