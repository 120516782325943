import { useState,useEffect } from 'react';
import { useParams } from "react-router-dom";
import './UpdateComp.css';
import { updateCompetitionInfo,getEventDetailsGeneral,getEventDetailsAdmin } from '../../services/AWS/competitionInformation/competitionInfo';
import { MastereventList, saveSelectedEventsToString, saveSelectedEventsPriceToString } from '../../ultis/masterData';
import { useDispatch } from 'react-redux';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import moment from "moment/moment";

const UpdateCompetitionPage = () => {
    const [SelectedDate,setSelectedDate] = useState(moment.unix());
    const [selectedCloseDate,setSelectedCloseDate] = useState(moment.unix());

    const shouldDisableTime=(value, view) =>{return (
        view === 'minutes' && value.minute() >= 1)
}
const handleDateChange = (newValue) => {
    setSelectedDate((dayjs(newValue).second(0).unix()));
}

const handleDateCloseChange = (newValue) => {
    setSelectedCloseDate((dayjs(newValue).second(0).unix()));
}


const {id} = useParams();
const [competitionData, setCompetitionData] = useState({});
const [errorMessages, setErrorMessages] = useState({});
const dispatch = useDispatch();
const userAuthtoken = localStorage.getItem('MYCAAdminToken');
  let InitialListMap = {};
  const eventList = MastereventList;
  //map eventList to InitalMap with false value
  for (let i = 0; i < eventList.length; i++) {
    InitialListMap[eventList[i]] = false;
  }
  let InitialPricemap = {};
  for (let i = 0; i < eventList.length; i++) {
    InitialPricemap[eventList[i]] = "0";
  }

  const [EventToParticipate, setEventToParticipate] = useState(InitialListMap);
  const [eventPrice, setEventPrice] = useState(InitialPricemap);
  const [qualificationTime, setQualificationTime] = useState({});
  const [qualificationType, setQualificationType] = useState({});
    const [qualificationMethod, setQualificationMethod] = useState({});
  const handlePriceChange = (event) => {
    const { name, value } = event.target;
    // setEventPrice
    setEventPrice((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleEventQualificationTimeChange = (event) => {
    const { name, value } = event.target;
      setQualificationTime((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }
  const handleEventQualificationTypeChange = (event) => {
    const { name, value } = event.target;
      setQualificationType((prevData) => ({
      ...prevData,
      [name]: value
    }));
  }

    const handleEventQualificationMethodChange = (event) => {
        const { name, value } = event.target;
        setQualificationMethod((prevData) => ({
            ...prevData,
            [name]: value
        }));
    }

  const displayQualificationTime = (event) => {
      if (!qualificationTime.hasOwnProperty(event)|| qualificationTime[event] === null) {
          return 0;
      }
    return qualificationTime[event];

  }

    const displayQualificationType = (event) => {
        if (!qualificationType.hasOwnProperty(event) || qualificationType[event] === null) {
            return "single";

    }
    return qualificationType[event];
    }
    const displayQualificationMethod = (event) => {
        if (!qualificationMethod.hasOwnProperty(event) || qualificationMethod[event] === null) {
            return "score";

        }
        return qualificationMethod[event];
    }

  useEffect(() => {
    getCompInfo(id);
  }, []);
  const handleEventsCheckboxChange = (event) => {
    setEventToParticipate((prevData) => ({
      ...prevData,
      [event.target.name]: !EventToParticipate[event.target.name]
    }));

  }
  const validateSelectedEvent = (EventToParticipate, eventPrice) => {
    let haveEvent = false;


    for (const entry of eventList) {
      let key = entry;
      let value = EventToParticipate[entry];
      if (value === true) {
        haveEvent = true;
      }

    }
    if (!haveEvent) {
      setErrorMessages({ name: "General", message: "Please select at least one event" });
      return false;
    }
    if (eventPrice.size === 0 && EventToParticipate.size != 0) {
      setErrorMessages({ name: "General", message: "Please enter price for each event" });
      return false;
    }
    // for all event in EventToParticipate, event price must have a number value
    for (const entry of eventList) {


      let key = entry;
      let value = EventToParticipate[entry];
      if (value == false || value == undefined) {
        continue;
      }
      if (!eventPrice[key]) {
        setErrorMessages({ name: "General", message: "Please enter price for  event " + key });
        return false;
      }
      else if (isNaN(eventPrice[key])) {
        setErrorMessages({ name: "General", message: "Price must be a number" });
        return false;
      }
    }
    return true;
  }
  const handleSubmit = async (event) => {
      event.preventDefault();
      dispatch({ type: 'loading' });

      const competitionDataToInsert = competitionData;
      const validateInputResult = await validateInput(competitionData);
      const validateEventResult = await validateSelectedEvent(EventToParticipate, eventPrice);
    if (!validateInputResult || !validateEventResult) {
      dispatch({ type: 'loaded' });
            return;
      }
    let qualificationToInsert = null;
    //se to example of 3x3x3:average:100;4x4x4:single:9000
//loop eventlist and check in qualification time
        for (let i = 0; i < eventList.length; i++) {
            if (EventToParticipate[eventList[i]] === true) {
                if (qualificationTime[eventList[i]]) {
                    if (!qualificationType[eventList[i]]) {
                        qualificationType[eventList[i]] = "single";
                    }
                    if (!qualificationMethod[eventList[i]]) {
                        qualificationMethod[eventList[i]] = 'score';
                    }
                    if (Number(qualificationTime[eventList[i]]) !== 0) {
                        if (qualificationToInsert == null) {
                            qualificationToInsert = eventList[i] + ":" + qualificationType[eventList[i]] + ":"  + qualificationMethod[eventList[i]] + ":" + qualificationTime[eventList[i]] + ";"
                        }
                        else {
                            qualificationToInsert += eventList[i] + ":" + qualificationType[eventList[i]] + ":"  + qualificationMethod[eventList[i]] + ":" + qualificationTime[eventList[i]] + ";"
                        }

                    }

                }
            }
        }
      competitionDataToInsert.Events = await saveSelectedEventsToString(EventToParticipate);
      competitionDataToInsert.Events_price = await saveSelectedEventsPriceToString(eventPrice, EventToParticipate);
        competitionDataToInsert.qualification = qualificationToInsert;
    competitionDataToInsert.Base_charges = "Base_charges:" + competitionDataToInsert.Raw_base_charge + ";Admin_charges:" + competitionDataToInsert.Raw_Admin_charges + ";Guest_charges:" + competitionDataToInsert.Raw_Guest_charges;
      competitionDataToInsert.registrationOpenTime = SelectedDate;
        competitionDataToInsert.registrationCloseTime = selectedCloseDate;
      const Apirespond = await updateCompetitionInfo(competitionDataToInsert,userAuthtoken);

           if (Apirespond.data != "success!") {
            setErrorMessages({name:"General", message:Apirespond.data});
          }
          else{
            setErrorMessages({name:"General", message:"success, you may close this window now!"});
    }
    dispatch({ type: 'loaded' });
  };
  

    const validateInput = (data) => {
        if (!data.ActiveStatus){
            setErrorMessages({name: "General", message: "Active Status is required"});
            return false;
        }
        if (!data.WaitingList_limit){
            setErrorMessages({name: "General", message: "WaitingList limit is required"});
            return false;
        }
        if (!data.Participant_limit){
            setErrorMessages({name: "General", message: "Participant limit is required"});
            return false;
        }
        if (!data.Competition_name){
            setErrorMessages({name: "General", message: "Competition name is required"});
            return false;
        }
        if (Number(data.Participant_limit) > Number(data.WaitingList_limit)) {
            setErrorMessages({ name: "General", message: "Participant limit should be less than waiting list limit" });
            return false;
            }
        if (!SelectedDate) {
            setErrorMessages({name:"General", message:"Registration Open Date is required"});
            return false;
        }
        if (!selectedCloseDate) {
            setErrorMessages({name:"General", message:"Registration Close Date is required"});
            return false;
        }


        return true;

    }
  const getCompInfo = async (id) => {
    dispatch({ type: 'loading' });
      const compData = await getEventDetailsAdmin(id,userAuthtoken);
      compData.Raw_base_charge = 0;
      compData.Raw_Admin_charges = 0;
    //loop compData.Base_charges
    const Base_charges_array = compData.Base_charges.split(";");
    for (let i = 0; i < Base_charges_array.length; i++) {
        if (Base_charges_array[i].split(":")[0] == "Base_charges") {
            compData.Raw_base_charge = Base_charges_array[i].split(":")[1];
        }
        else if (Base_charges_array[i].split(":")[0] == "Admin_charges") {
            compData.Raw_Admin_charges = Base_charges_array[i].split(":")[1];
        }
        else if (Base_charges_array[i].split(":")[0] == "Guest_charges") {
            compData.Raw_Guest_charges = Base_charges_array[i].split(":")[1];
        }
    }
    if (compData.Raw_Guest_charges === undefined) {
        compData.Raw_Guest_charges = 0;
    }
   if (compData.registerOpen === "true") {
        compData.registerOpen = true;
        }
    if (compData.Auto_approve === "true") {
        compData.Auto_approve = true;
    }
    if (compData.allowPostApprovalEdit === "true") {
        compData.allowPostApprovalEdit = true;
    }
    if (compData.registerOpen === "false") {
        compData.registerOpen = false;
    }
    if (compData.Auto_approve === "false") {
        compData.Auto_approve = false;
    }
    if (compData.allowPostApprovalEdit === "false") {
        compData.allowPostApprovalEdit = false;
    }
      if (compData.allowPostApprovalEdit === null || compData.allowPostApprovalEdit === undefined) {
          compData.allowPostApprovalEdit = false;
      }
    setCompetitionData(compData);

    //loop  compData.Events_price to setEventPrice and setEventToParticipate
    const Events_price = compData.Events_price.split(",");
    let tempEventPrice = eventPrice;
    let tempEventToParticipate = EventToParticipate;
    for (let i = 0; i < Events_price.length; i++) {
      tempEventPrice[Events_price[i].split(":")[0]] = Events_price[i].split(":")[1];
      tempEventToParticipate[Events_price[i].split(":")[0]] = true;

    }
    setEventPrice(tempEventPrice);
    setEventToParticipate(tempEventToParticipate);
    if (compData.registrationOpenTime && compData.registrationOpenTime !=undefined)
      setSelectedDate(compData.registrationOpenTime);
    if (compData.registrationCloseTime && compData.registrationCloseTime !=undefined)
        setSelectedCloseDate(compData.registrationCloseTime);

    if (compData.hasOwnProperty('qualification') && compData.qualification != null) {
        let qualificationArray = compData.qualification.split(";");
        for (let i = 0; i < qualificationArray.length; i++) {
            if (qualificationArray[i] == null || qualificationArray[i] == "") {
                continue;
            }
            let event = qualificationArray[i].split(":")[0];
            let type = qualificationArray[i].split(":")[1];
            let method = qualificationArray[i].split(":")[2];
            let time = qualificationArray[i].split(":")[3];
            setQualificationTime((prevData) => ({
                ...prevData,
                [event]: time
            }));
            setQualificationType((prevData) => ({
                ...prevData,
                [event]: type
            }));
            setQualificationMethod((prevData) => ({
                ...prevData,
                [event]: method
            }));
        }
    }

    dispatch({ type: 'loaded' });

  }
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        let inputValue = value;
        setCompetitionData((prevData) => ({
            ...prevData,
            [name]: inputValue
        }));
        };
    const handleCheckBoxClick = (event) => {
        const { name, value } = event.target;
        setCompetitionData((prevData) => ({
            ...prevData,
            [name]: competitionData[name] ? false : true
        }));
    };
        const renderErrorMessage = (name) =>
        name === errorMessages.name && (
          <div className="error">{errorMessages.message}</div>
        );


    return(
        <div>
        <h2>{competitionData.Competition_name}</h2>

            <div className="register-form">
                <form onSubmit={handleSubmit}>
                    <div className="input-container">
                        <label>
                            Competition Name:</label>
                        <input
                            type="text"
                            name="Competition_name"
                            value={competitionData.Competition_name}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Competition Date:</label>
                        <input
                            type="text"
                            name="Competition_date"
                            value={competitionData.Competition_date}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Venue:</label>
                        <input
                            type="text"
                            name="Venue"
                            value={competitionData.Venue}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Address:</label>
                        <input
                            type="text"
                            name="Address"
                            value={competitionData.Address}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            address details:</label>
                        <input
                            type="text"
                            name="address_details"
                            value={competitionData.address_details}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            contact email:</label>
                        <input
                            type="text"
                            name="contact_email"
                            value={competitionData.contact_email}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Organizers: </label>
                        <input
                            type="text"
                            name="Organizers"
                            value={competitionData.Organizers}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            WCA Delegates: </label>
                        <input
                            type="text"
                            name="wca_delegates"
                            value={competitionData.wca_delegates}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Pre-Register WCA Emails/WCA ID (Seperate by ";"): </label>
                        <textarea
                            type="textare"
                            name="whiteList"
                            value={competitionData.whiteList}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Authorize WCA Email To handle Registry (Seperate by ";"): </label>
                        <textarea
                            type="textare"
                            name="Register_Access"
                            value={competitionData.Register_Access}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Active Status (Visible on website): </label>
                        <select value={competitionData.ActiveStatus} onChange={handleInputChange} name="ActiveStatus">
                            <option value="Active">Active</option>

                            <option value="Inactive">Inactive</option>

                        </select>
                    </div>
                    <div className="input-container">
                        <label>Registration Open Date (in UTC +8 time. Competition Active must be set to true for auto
                            open registration to work)* </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker shouldDisableTime={shouldDisableTime} disablePast
                                                   onChange={(newValue) => handleDateChange(newValue)}
                                                   value={dayjs(moment.unix(SelectedDate).format('YYYY-MM-DD h:mm:ss a'))}/>
                        </LocalizationProvider>
                    </div>
                    <div className="input-container">
                        <label>Registration Close Date (in UTC +8 time. Competition Active must be set to true for auto
                            open registration to work)* </label>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateTimePicker shouldDisableTime={shouldDisableTime} disablePast
                                                   onChange={(newValue) => handleDateCloseChange(newValue)}
                                                   value={dayjs(moment.unix(selectedCloseDate).format('YYYY-MM-DD h:mm:ss a'))}/>
                        </LocalizationProvider>
                    </div>
                    <div>
                        <input type="checkbox" checked={competitionData.registerOpen} onClick={handleCheckBoxClick}
                               name="registerOpen"/>
                        Open Registration

                    </div>
                    <div>
                        <input type="checkbox" checked={competitionData.Auto_approve} onClick={handleCheckBoxClick}
                               name="Auto_approve"/>
                        Auto Approve after competitor make payment

                    </div>
                    <div>
                        <input type="checkbox" checked={competitionData.allowPostApprovalEdit}
                               onClick={handleCheckBoxClick}
                               name="allowPostApprovalEdit"/>
                        Allow participant edit registration after approval without extra charges

                    </div>
                    <div>
                        <input type="checkbox" checked={competitionData.enforceUnderAgeGuestTicket}
                               onClick={handleCheckBoxClick}
                               name="enforceUnderAgeGuestTicket"/>
                        Competitor under 18 years old must purchase guest ticket

                    </div>
                    <div className="input-container">
                        <label>
                            Participant Limit:</label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            name="Participant_limit"
                            value={competitionData.Participant_limit}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Participant including Waiting List Limit: </label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            name="WaitingList_limit"
                            value={competitionData.WaitingList_limit}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Base Charge:</label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            name="Raw_base_charge"
                            value={competitionData.Raw_base_charge}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <label>
                            Admin Charge:</label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            name="Raw_Admin_charges"
                            value={competitionData.Raw_Admin_charges}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>
                            Per Guest Charge:</label>
                        <input
                            type="text"
                            pattern="[0-9]*"
                            name="Raw_Guest_charges"
                            value={competitionData.Raw_Guest_charges}
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="input-container">
                        <label>
                            WCA Url:</label>
                        <small>do not include "https" and "www" (e.g.:
                            worldcubeassociation.org/competitions/PleaseBeQuietBukitJalil2023)</small>
                        <input
                            type="text"
                            name="wcaURL"
                            value={competitionData.wcaURL}
                            onChange={handleInputChange}
                        />

                    </div>
                    <div className="input-container">
                        <h2>Events setting</h2>
                        <h4>leave qualification time to 0 if not setting up qualification for that event</h4>
                        <h4>The qualification condifition will be less then the value set, greater than for multi blind
                            event</h4>
                        <h4>The default qualification type is single, please check before submit</h4>
                        {eventList.map((item) => (
                            <EventListSelection event={item} EventToParticipate={EventToParticipate}
                                                handleEventsCheckboxChange={(event) => {
                                                    handleEventsCheckboxChange(event)
                                                }}
                                                handlePriceChange={(event) => {
                                                    handlePriceChange(event)
                                                }}
                                                handleEventQualificationTimeChange={(event) => {
                                                    handleEventQualificationTimeChange(event)
                                                }}
                                                handleEventQualificationTypeChange={(event) => {
                                                    handleEventQualificationTypeChange(event)
                                                }}
                                                handleEventQualificationMethodChange={(event) => {
                                                    handleEventQualificationMethodChange(event)
                                                }}
                                                displayQualificationTime={displayQualificationTime}
                                                displayQualificationType={displayQualificationType}
                                                displayQualificationMethod={displayQualificationMethod}
                                                eventPrice={eventPrice}/>
                        ))}

                    </div>
                    {renderErrorMessage("General")}
                    <div className="button-container">
                        <input type="submit"/>
                    </div>
                </form>

            </div>
        </div>
    )
}

const EventListSelection = (props) => {
    //
    return (
        <div>
            <div>
                <input type="checkbox" name={props.event}
                       checked={props.EventToParticipate[props.event]} value={props.EventToParticipate[props.event]}
                       onChange={props.handleEventsCheckboxChange}/>
                {props.event}
            </div>
            <div>
            <label>
                        Price:
                    </label>
                    <input style={{marginLeft:"5px",marginRight:"5px",width:"50px"}}  key={props.event} pattern="[0-9]*" type="text" name={props.event}
                           value={props.eventPrice[props.event]} onChange={props.handlePriceChange}/>

                    {props.EventToParticipate[props.event] &&
                        <div style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px", marginBottom: "10px"}}>
                            <label style={{marginLeft: "5px", marginRight: "5px"}}>
                                Qualification Type:
                            </label>
                            <select style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px"}}
                                    value={props.displayQualificationType(props.event)}
                                    onChange={props.handleEventQualificationTypeChange}
                                    name={props.event}>
                                <option value="single">single</option>
                                <option value="average">average</option>

                            </select>
                            <label style={{marginLeft: "5px", marginRight: "5px"}}>
                                Qualification method:
                            </label>
                            <select style={{marginLeft: "5px", marginRight: "5px", marginTop: "5px"}}
                                    value={props.displayQualificationMethod(props.event)}
                                    onChange={props.handleEventQualificationMethodChange}
                                    name={props.event}>
                                <option value="ranking">World Ranking</option>
                                <option value="score">Time/Score</option>

                            </select>
                            <label style={{marginLeft: "5px", marginRight: "5px"}}>
                                 Value (seconds*100,score for fmc and mlb):
                            </label>
                            <input style={{marginLeft: "5px", marginRight: "5px", width: "50px"}}
                                   key={props.event}
                                   pattern="[0-9]*"
                                   type="text"
                                   name={props.event}
                                   value={props.displayQualificationTime(props.event)}
                                   onChange={props.handleEventQualificationTimeChange}/></div>}
                </div>
        </div>
    )
}
export default UpdateCompetitionPage;
