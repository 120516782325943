import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import InboxIcon from '@mui/icons-material/Inbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import { getEventList } from '../../../services/AWS/competitionInformation/competitionInfo';
import { useDispatch } from 'react-redux';
import  { useState ,useEffect} from "react";


const  ListingElement = (prop) =>{
return(

    <div>
           <ListItem disablePadding >
            <ListItemButton sx={{ justifyContent: 'center'}} href={`/competitionView/${prop.competitionInfo.competition_ID}`}>
              <ListItemText primary={prop.competitionInfo.Competition_name} />
            </ListItemButton>
          </ListItem>
          </div>
)
}
 const CompetitionList = () => {
  const [ActiveEventList,setActiveEventList] = useState([""]);
  const [InactiveEventList,setInactiveEventList] = useState([""]);
   const [isLoading, setIsLoading] = useState(false);
   const dispatch = useDispatch();
   useEffect(() => {
  
      getCompetitionList("All");

    },[]);

  const  getCompetitionList =async (active) =>{
    dispatch({ type: 'loading' });
      const eventList2 = await getEventList(active);
let activeList = [];
let InActiveList = [];
        if (eventList2 && eventList2.length > 0){
            eventList2.forEach(element => {
                if (element.ActiveStatus == "Active"){
                    activeList.push(element);
                }
                else{
                    InActiveList.push(element);
                }
            });
        }
        setActiveEventList(activeList);
        setInactiveEventList(InActiveList);
    dispatch({ type: 'loaded' });
   }
    return (
        <div>
            <h1>Competition List</h1>
            <nav aria-label="secondary mailbox folders">
                <h1>
                    Active Competitions
                </h1>
        <List>

        {ActiveEventList.map((item) => (
                    <ListingElement  competitionInfo={item} />
                 ))}
        </List>
                <h1>
                    InActive Competitions
                </h1>
                <List>

                    {InactiveEventList.map((item) => (
                        <ListingElement  competitionInfo={item} />
                    ))}
                </List>
      </nav>
        </div>
    )
}


export default CompetitionList;
