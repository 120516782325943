import {useEffect,useState,useRef} from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams,useGridApiRef,GridEventListener } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import useCopy from "use-copy";
import { filterObjectByKeyvalue, filterObjectByKeyPartialvalue, returnEmailList,returnIDList } from '../../ultis/filterObject/filterObject';
import { FormatColorReset, Padding } from '@mui/icons-material';
import { display } from '@mui/system';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Fab from '@mui/material/Fab';
import NavigationIcon from '@mui/icons-material/Navigation';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { updateParticipantStatus,RefundAndDeleteParticipant, generateWCAFile } from '../../services/AWS/competitionInformation/competitionInfo';
import config from '../../config.json';
import {   GridApi, GridCellValue } from '@mui/x-data-grid';
import iconv from 'iconv-lite';
import TextField from '@mui/material/TextField';
import {useDispatch} from "react-redux";
import {retry} from "@aws-amplify/core";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {generateParticipantCSV} from "../../ultis/masterData";


const downloadFile = (csvContent, fileName) => {

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      // For IE browser
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const objectUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = objectUrl;
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(objectUrl);
    }
  };


export default function ParticipantTable(props) {

  const dispatch = useDispatch();
  const columns = [
    {
      field: "Edit",
      headerName: "Edit",
      sortable: false,
      renderCell: (params) => {
        const onEditClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setEditParticipant(true);
          const currentRow = params.row;
          //route to EditParticipantInfo page with new tab
          window.open('/EditParticipantInfo/' + currentRow.ID, '_blank');
        };

        return <Button onClick={onEditClick}>Edit</Button>;
      }
      },
    {
      field: 'RunningNumber',
      headerName: 'RunningNumber',
      width: 100,
      editable: false,

    },
    {
      field: 'UserName',
      headerName: 'Name',
      width: 200,
      editable: false,
    },
    {
      field: 'country',
      headerName: 'country',
      width: 100,
      editable: false,
    },
    {
      field: 'WCAID',
      headerName: 'WCAID',
      width: 80,
      editable: false,
    },

    {
      field: 'Register_date',
      headerName: 'Data Registered',
      width: 100,
      editable: false,
    },
    {
      field: 'attended',
      headerName: 'Checked In',
      width: 100,
      editable: false,
    },
    {
      field: 'checkInDateTime',
      headerName: 'Data Check In',
      width: 100,
      editable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: false,
    },
    {
      field: 'remark',
      headerName: 'remark',
      width: 150,
      editable: false,
    },
    {
      field: 'CheckInRemark',
      headerName: 'CheckInRemark',
      width: 150,
      editable: false,
    },
    {
      field: 'Outstanding',
      headerName: 'Outstanding',
      width: 150,
      editable: false,
    },
    {
      field: 'totalCharges',
      headerName: 'total Charges',
      width: 150,
      editable: false,
    },
    {
      field: 'paid',
      headerName: 'paid',
      width: 100,
      editable: false,
    },
    {
      field: 'paymentMethod',
      headerName: 'Payment Method',
      width: 100,
      editable: false,
    },
    {
      field: 'pastPaymentHistory',
      headerName: 'Payment History',
      width: 200,
      editable: false,
    },
    {
      field: 'StripePaymentID',
      headerName: 'StripePaymentID',
      width: 200,
      editable: false,
    },
    {
      field: 'PaymentID',
      headerName: 'Curlec Order ID',
      width: 200,
      editable: false,
    }
    ,
    {
      field:'LinkedWCAEmail',
      headerName:'Linked WCA Email',
      width: 300,
      editable: false,
    },
    {
        field: 'compLocation',
        headerName: 'Selected Location',
        width: 100,
        editable: false,
    },



  ];

  const rows = props.list;
  const apiRef = useGridApiRef();
  const [message, setMessage] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [FilterState, setFilterState] = useState('Approved');
  const [filteredRows,setFilteredRow] = useState([]);
  const [EditParticipant, setEditParticipant] = useState(false);
  const [refundDialogOpen, setRefundDialogOpen] = useState(false);
  const [participantInfoDetails, setParticipantInfoDetails] = useState({});
  const [refundAmount, setRefundAmount] = useState(0);
  const [refundRow,setRefundRow] = useState([]);
  const [refundDialogMessage, setRefundDialogMessage] = useState('');
  const [filterText, setFilterText] = useState('');
  const handleBoxChange = (event) => {
    setSelectedRows([]);
    setFilterState(event.target.value);
  };
  useEffect(() => {
    let filteredRow;
  if (FilterState == 'Approved'){
    filteredRow = filterObjectByKeyvalue(rows,true,'approved');
  }
  else if (FilterState == 'Pending_Approval'){
    filteredRow = filterObjectByKeyvalue(rows,true,'waitingList');
  }
  else if (FilterState == 'Deleted'){
    const pendingRows = filterObjectByKeyvalue(rows,false,'approved');
  filteredRow =  filterObjectByKeyvalue(pendingRows,false,'waitingList');
  }
  else{
    filteredRow = rows;
    }
  if (filterText.trim() != '') { 
    filteredRow = filterObjectByKeyPartialvalue(filteredRow, filterText, 'UserName');
    };
  
    setFilteredRow(filteredRow)

  }, [FilterState, filterText,rows]);
  const handleFilterNameChange = (event) => {

setFilterText(event.target.value);

  }
  const [copied, copy, setCopied] = useCopy("");
  const [isCopied, setIsCopied] = useState(false);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
      return await navigator.clipboard.writeText(text);

  }
  const handleRefundsubmit = async (selectedRows) => {
    dispatch({type: 'loading'})
    setRefundDialogMessage('');
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    if (refundAmount == 0){
      setRefundDialogMessage('Refund Amount cannot be 0!');
      dispatch({type: 'loaded'})
      return;
    }
    const isApprove = FilterState == 'Approved' ? true : false;
    await RefundAndDeleteParticipant([selectedRows.ID], userAuthtoken, isApprove, false,true,parseFloat(refundAmount).toFixed(2));
    dispatch({type: 'loaded'})
    setRefundDialogOpen(false);
    //refresh page
    window.location.reload();
  }
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    const EmailList = returnEmailList(filteredRows);
    console.log(EmailList)
    copyTextToClipboard(EmailList)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  const handleCopyIDClick = () => {
    // Asynchronously call copyTextToClipboard
    const IDlist = returnIDList(filteredRows);
    copyTextToClipboard(IDlist)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
  }
  
 useEffect(() => {
    const rowSelectionChange = (params) => {
 //     console.log(params.id);
      let newSelectedRows = []; 
if (selectedRows != null ){
  newSelectedRows =selectedRows ;
}
     
      if (params.value == true){
        newSelectedRows.push(params.id) 
              // append selectedRows array
        setSelectedRows(newSelectedRows);
      }
        
      else{
        var index = newSelectedRows.indexOf(params.id);
        newSelectedRows.splice(index, 1);
              // remove from selectedRows array
        setSelectedRows(newSelectedRows);
      }
      console.log(newSelectedRows)

    };

    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent('rowSelectionCheckboxChange', rowSelectionChange);
  }, [apiRef,selectedRows]);

  useEffect(() => {
    const rowSelectionChange = (params) => {

         if (params.value == false){
          setSelectedRows([]);
         }
         else if (params.value == true){
           let newSelectedRows = []; 
           for (let i = 0; i < filteredRows.length; i++) {
             newSelectedRows.push(filteredRows[i].ID);
           }
           setSelectedRows(newSelectedRows);
           console.log(newSelectedRows);
         }
        };

    return apiRef.current.subscribeEvent('headerSelectionCheckboxChange', rowSelectionChange);

  }, [apiRef, rows, filteredRows]);
const handleButtonClick =async (event,value) => {
try {
  dispatch({type: 'loading'});
  if (selectedRows.length == 0) {
    setMessage('Please select at least one row');
    return;
  }
  if (value == 'Approve') {
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    await updateParticipantStatus(props.CompId, selectedRows, userAuthtoken, true, false,false);

  } else if (value == 'Decline') {
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    await updateParticipantStatus(props.CompId, selectedRows, userAuthtoken, false, false,false);

  } else if (value == 'Delete') {
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    await updateParticipantStatus(props.CompId, selectedRows, userAuthtoken, false, true,false);

  } else if (value == 'Restore') {
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    await updateParticipantStatus(props.CompId, selectedRows, userAuthtoken, false, false,false);

  }
 else if (value == 'deleteRefund') {
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    await updateParticipantStatus(props.CompId, selectedRows, userAuthtoken, false, true,true);

  }
  dispatch({type: 'loaded'});
//refresh page
  window.location.reload();
}
catch (error) {
  dispatch({type: 'loaded'});
    setMessage(error.message);
}

}
const handleEditButtonClick = () =>{
  //route to edit page
  window.location.href = '/UpdateCompetition/'+props.CompId;
  }
  const WCAExcelButtonClick = async() => {
    //route to edit page
    const userAuthtoken = localStorage.getItem('MYCAAdminToken');
    const respond = await generateWCAFile(props.CompId, userAuthtoken)
    console.log(respond);
    let RawData = respond.data.data;
    await downloadFile(RawData,`${props.CompName}_WCA_Import`,respond.headers["content-type"]);
  }

  const handleGenerateParticipantListClick = async() => {
    const particpantCsv =await generateParticipantCSV(rows);

    await downloadFile(particpantCsv,`${props.CompName}_participant_Data`,"text/csv");
  }
  return (

    <Box sx={{ height: 600, width: '150%', flexdirection: "row" }}>
      <Box sx={{minWidth: 100, maxWidth: 100, marginLeft: 10}}>
        <h1 style={{minWidth: 1000, textAlign: 'left'}}>{props.compInfo.Competition_name}
          <br/>

        </h1>
        <h4 style={{minWidth: 1000, textAlign: 'left'}}>
          Delete: Delete will
          remove the payment information and go to pending payment list. This is irreversible so
          please double check before use.
          <br/>
          <br/>
          The waiting list count will only be increment and no deduction will be made, which is a approximate counter.
          If want to let more people join
          waiting list, just increase waiting list limit.
        </h4>
        <h4 style={{minWidth: 1000, textAlign: 'left',color:'red'}}>
          *Please remember to untick auto approve if registration already full and you want to manually approve
        </h4>
          <table style={{minWidth: 1000, textAlign: 'left'}}>
            <tr>
              <td>Approved count: {props.compInfo.approved_count}</td>
            </tr>
            <tr>
              <td>Participant Limit: {props.compInfo.Participant_limit}</td>
            </tr>
            <tr>
              <td>Waiting List + Participant count: {props.compInfo.waiting_list_count} </td>
            </tr>
            <tr>
              <td>Waiting List + Participant Limit: {props.compInfo.WaitingList_limit}</td>
            </tr>
          </table>
          <br/>
          <div style={{display: "flex", minWidth: 600,gap:"5px"}}>
            <Button variant="contained" color="success" onClick={handleCopyClick}>copy Email</Button>
            <Button variant="contained" color="success" onClick={handleCopyIDClick}>copy id</Button>
            <Button variant="contained" color="success" onClick={handleEditButtonClick}>Edit Competition</Button>
            <Button variant="contained" color="secondary" onClick={WCAExcelButtonClick}>Generate WCA Excel</Button>
            <Button variant="contained" color="secondary" onClick={handleGenerateParticipantListClick}>Generate Full
              Participant List</Button>
          </div>

      </Box>
      <Box sx={{marginBottom: "1%", minWidth: 100, maxWidth: 200, marginLeft: 10}}>
        <TextField id="standard-basic" label="Filter Name" variant="standard" onChange={handleFilterNameChange}/>
      </Box>
      <Box sx={{ minWidth: 100,maxWidth: 200,marginLeft:10 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Registration Status</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={FilterState}
          label="Registration Status"
          onChange={handleBoxChange}
        >
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Deleted"}>Pending Payment</MenuItem>
          <MenuItem value={"Pending_Approval"}>Paid Waiting List</MenuItem>
        </Select>
      </FormControl>
    </Box>
       <DataGrid
           sx={{  width: '80vw',height: '80vh'}}
        rows={filteredRows}
        columns={columns}
        getRowId={(row) => row.ID}
        pageSize={100}
        apiRef={apiRef}
       // checkboxSelection
        disableSelectionOnClick
        checkboxSelection
      />  
      {message && <Alert severity="info">{message}</Alert>}
      <Box sx={{ '& > :not(style)': { m: 1 } }}>
        {(FilterState == 'Pending_Approval'||FilterState == 'Deleted')  && <Button  variant="contained" color="success" onClick={(event) => {
    handleButtonClick(event,"Approve")}}>Approve</Button>}
        {FilterState == 'Approved'  && <Button variant="contained" color="secondary" onClick={(event) => {
    handleButtonClick(event,"Decline")}}>Move To Waiting</Button>}
        {(FilterState == 'Approved' || FilterState == 'Pending_Approval') && <Button  variant="contained" color="error" onClick={(event) => {
    handleButtonClick(event,"Delete")}}>Delete</Button>}
        {/*(FilterState == 'Approved' || FilterState == 'Pending_Approval'  ) && <Button  variant="contained" color="error" onClick={(event) => {
          handleButtonClick(event,"deleteRefund")}}>Delete with Refund</Button> */}

    </Box>
      <RefundDialog errorMessage={refundDialogMessage} handleRefundsubmit={(selectedRow) => handleRefundsubmit(selectedRow)} refundRow = {refundRow} open={refundDialogOpen} setRefundAmount = {(refundAmount)=>setRefundAmount(refundAmount)} handleClose={() => setRefundDialogOpen(false)} />
    </Box>
  );
}
const RefundDialog = (props) => {
  return (
      <Dialog open={props.open} onClose={props.handleClose}>
        <DialogTitle>Refund</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter the amount (RM) to refund for {props.refundRow.UserName}
            <br/>
                Total Paid Amount: {props.refundRow.totalPaid}
          </DialogContentText>
          <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Amount"
              type="number"
              fullWidth
              variant="standard"
              onChange={(event) => {
                props.setRefundAmount(event.target.value);
              }}
          />
        </DialogContent>
        <DialogContentText sx={{color:"red"}}>
          {props.errorMessage}
        </DialogContentText>
        <DialogActions>
          <Button onClick={props.handleClose}>Cancel</Button>
          <Button onClick={() => props.handleRefundsubmit(props.refundRow)}>Submit</Button>
        </DialogActions>
      </Dialog>
  )
}
