import React, { useEffect, useState } from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Auth } from "aws-amplify";
import {
    getMerchantFormList,
    UpdateParticipantAttendance
} from "../../services/AWS/competitionInformation/competitionInfo";
import Button from "@mui/material/Button";
import { generateCSV, UpdateType } from "../../ultis/masterData";

const getProperty = (obj, path) => {
    if (obj.hasOwnProperty(path)) {
        return obj[path];
    }
    return 0;
}

const MerchantFormListPage = () => {
    const [list, setItems] = useState([]);
    const [filteredList, setFilteredList] = useState([]);
    const [isGridLoading, setIsGridLoading] = useState(false);
    const [nameFilter, setNameFilter] = useState('');

    const getStaffListFromAPI = async () => {
        const userAuth = await Auth.currentSession();
        const MerchantList = await getMerchantFormList(userAuth.idToken.jwtToken);
        const rawJson = MerchantList.list;

        for (let i = 0; i < rawJson.length; i++) {
            const productData = JSON.parse(rawJson[i].Product);
            if (productData.hasOwnProperty("BundleA")) {
                rawJson[i].BundleA = productData.BundleA;
            }
            if (productData.hasOwnProperty("BundleB")) {
                rawJson[i].BundleB = productData.BundleB;
            }
            if (productData.hasOwnProperty("Keychain")) {
                rawJson[i].Keychain = productData.Keychain;
            }
            if (rawJson[i].hasOwnProperty("TshirtSize") && rawJson[i].TshirtSize !== "") {
                const tshirtData = JSON.parse(rawJson[i].TshirtSize);
                rawJson[i].size_XS = getProperty(tshirtData, "XS");
                rawJson[i].size_S = getProperty(tshirtData, "S");
                rawJson[i].size_M = getProperty(tshirtData, "M");
                rawJson[i].size_L = getProperty(tshirtData, "L");
                rawJson[i].size_XL = getProperty(tshirtData, "XL");
                rawJson[i].size_XXL = getProperty(tshirtData, "XXL");
                rawJson[i].size_XXXL = getProperty(tshirtData, "XXXL");
            }
        }
        setItems(rawJson);
        setFilteredList(rawJson);
    }

    useEffect(() => {
        getStaffListFromAPI();
    }, []);

    useEffect(() => {
        const filtered = list.filter(item =>
            item.Name.toLowerCase().includes(nameFilter.toLowerCase())
        );
        setFilteredList(filtered);
    }, [nameFilter, list]);

    const columns = [
        {field: 'Name', headerName: 'Full Name', width: 200},
        {field: 'Email', headerName: 'Email', width: 200},
        {field: 'attended', headerName: 'Taken', width: 200},
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleMerchantCollection(params.row)}
                >
                    {params.row.attended === true ? "Undo" : "Collect"}
                </Button>
            ),
        },
        {field: 'createdAt', headerName: 'created At', width: 200},
        {field: 'amount', headerName: 'Total Amount(RM)', width: 200},
        {field: 'CurlecPaymentID', headerName: 'Stripe ID', width: 200},
        {field: 'BundleA', headerName: 'Bundle A', width: 200},
        {field: 'BundleB', headerName: 'Bundle B', width: 200},
        {field: 'Keychain', headerName: 'Keychain', width: 200},
        {field: 'TshirtSize', headerName: 'Tshirt Size', width: 200},
    ];

    const columnsNameString = [
        "Name",
        "Email",
        "createdAt",
        "amount",
        "CurlecPaymentID",
        "BundleA",
        "BundleB",
        "Keychain",
        "size_XS",
        "size_S",
        "size_M",
        "size_L",
        "size_XL",
        "size_XXL",
        "size_XXXL",
    ];

    const handleMerchantCollection = async (merchantData) => {
        const userAuth = await Auth.currentSession();
        setIsGridLoading(true);
        await UpdateParticipantAttendance(
            merchantData.ID,
            "RubiksWCAAsianChampionship2024",
            !merchantData.attended,
            UpdateType.merchant,
            userAuth.idToken.jwtToken
        );
        setIsGridLoading(false);
        window.location.reload();
    };

    const handleGenerateParticipantListClick = async () => {
        const participantCsv = await generateCSV(list, columnsNameString);
        await downloadFile(participantCsv, `AC2024_Purchase_Data`, "text/csv");
    }

    const downloadFile = (csvContent, fileName) => {
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
            const objectUrl = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = objectUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl);
        }
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Merchant Form List</h1>

            <div className="mb-4">
                <input
                    style={{fontSize:"20px",height:"30px"}}
                    type="text"
                    placeholder="Filter by name..."
                    value={nameFilter}
                    onChange={(e) => setNameFilter(e.target.value)}
                    className="p-4 border rounded text-xl h-16 w-96 shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
            </div>

            <DataGrid
                className="w-[80vw] h-[80vh] mb-4"
                columns={columns}
                getRowId={(row) => row.ID}
                pageSize={100}
                rows={filteredList}
                loading={isGridLoading}
            />

            <Button
                variant="contained"
                color="secondary"
                onClick={handleGenerateParticipantListClick}
            >
                Generate Full List
            </Button>
        </div>
    );
}

export default MerchantFormListPage;