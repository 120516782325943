import { createStore } from 'redux';
const isLoadingState = (state = { isLoading: false }, action) => { 
    if (action.type === 'loading') {
        return {
            isLoading: true
       } 
    }
    else if (action.type === 'loaded') {
        return {
            isLoading: false
        }
    }
    return state;
};

const store = createStore(isLoadingState);

export default store;