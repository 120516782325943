import React from "react";
import "./AuthCheck.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from '../login/loginPage';

const AuthCheckPage = (props) =>{
const CheckLoginstate = () =>{
        return true;
    }
const RenderLoginPage = () => {
    return (
    <div className='Main'>
        <h1>Please Login to Proceed</h1>
        <LoginPage />
    </div>
         );}

if (!props.loginState){
        return(
            <div>
  <RenderLoginPage/>
  </div>
        )
    }
return (

    <div>
            <div>{props.children}</div>
    {!props.loginState &&  <LoginPage />}

    </div>

    
)


}

export default AuthCheckPage;