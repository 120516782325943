import {React, useEffect} from "react";
import {useState} from "react";
import {Button} from "@mui/material";
import TextField from "@mui/material/TextField";
import {ResendCompEmail} from "../../services/AWS/competitionInformation/competitionInfo";
import {useDispatch} from "react-redux";

const ResendCompTicketPage = () => {
    const [result, setResult] = useState("");
    const [input, setInput] = useState("");
    const dispatch = useDispatch();

    const handleInputChange = (event) => {
        setInput(event.target.value);


    }

    const handleSendButtonClick =async () => {
        //seperate input by ;
        dispatch({type: 'loading'})
        const idList = input.split(";");
        const userAuthtoken = localStorage.getItem('MYCAAdminToken');
        //loop with for loop
        let resultString = "";
        for (let i = 0; i < idList.length; i++) {
            const rowID = idList[i];
            if (rowID === "") {
                continue;
            }
            resultString += idList[i] + " : " + "Attempt to Send" + "\n";

         const respond = await ResendCompEmail(rowID,userAuthtoken);
            resultString += rowID + " : " +  JSON.stringify(respond).substring(0,70) + "..." + "\n";
            setResult(resultString);
        }
        dispatch({type: 'loaded'});

    }
    return (
        <div>
            <label>Insert list of id seperated by ;</label>
            <TextField multiline rows={10} variant="outlined" value={input} fullWidth onChange={handleInputChange}/>
            <Button variant="contained" onClick={handleSendButtonClick}>Resend</Button>
            <br/>
            <h3>Result</h3>
            <TextField multiline rows={10} variant="outlined" contentEditable={false} value={result} fullWidth/>
        </div>
    );
}

export default ResendCompTicketPage;