import react, { useState,useEffect }  from 'react';
import { useParams } from "react-router-dom";
import ParticipantTable  from './participantTable';
import { getParticipantList } from '../../services/AWS/competitionInformation/competitionInfo';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import {Amplify,Auth} from 'aws-amplify';
import config from '../../config.json';
import moment from "moment";
const CompetitionPage = (props) => {
    const {id} = useParams();
    const [List, setItems] = useState([{ID:"dummy"}]);
    const [compInfo, setCompInfo] = useState({});
    const dispatch = useDispatch();
    const adminUserpool = config.AdminUserPool;
    const AWSRegion = config.AWSREGION;
    Amplify.configure({
      Auth: {
        userPoolId: adminUserpool.id, //UserPool ID
        region: AWSRegion,
        userPoolWebClientId: adminUserpool.webclient //WebClientId
      }
    });
    useEffect(() => {
        if (id){
            getPartList(id);
        }

      },[]);
const  getPartList =async (id) =>{
 dispatch({type:'loading'});
 const AuthInfo = await Auth.currentAuthenticatedUser();
 const userAuth =await Auth.currentSession();
        const compData = await getParticipantList(id,userAuth.idToken.jwtToken);
        const eventList2 = compData.list;
        //convert paymentInitiatedTime to datetime string
    if (eventList2 && eventList2.length > 0){
        eventList2.forEach(element => {
            eventList2.RunningNumber = Number(eventList2.RunningNumber);
            if (element.paymentInitiatedTime && element.paymentInitiatedTime != "0" && element.paid == true&& element.paymentInitiatedTime !== undefined){
                element.paymentInitiatedTime = moment.unix((element.paymentInitiatedTime/1000)).format("YYYY-MM-DD HH:mm:ss");
            }
            else {
                element.paymentInitiatedTime = "";
            }

            if (element.chargeDetails !== undefined && element.chargeDetails !== null){
                element.EventCharge = 0;
                element.Base_charges = 0;
                element.Admin_charges = 0;
                const chargeDetailsList = element.chargeDetails.toString().split(",");
                //loop chargeDetailsList to get charge details

                for (let i = 0; i < chargeDetailsList.length; i++) {
                    const chargeDetail = chargeDetailsList[i].split(":");
                    if (chargeDetail[0] == "EventCharge"){
                        element.EventCharge = chargeDetail[1];
                    }
                    else if (chargeDetail[0] == "Base_charges"){
                        element.Base_charges = chargeDetail[1];
                    }
                    else if (chargeDetail[0] == "Admin_charges"){
                        element.Admin_charges = chargeDetail[1];
                    }
                    else if (chargeDetail[0] == "Guest_charges"){
                        element.Guest_charges = chargeDetail[1];
                    }
                }
            }

        });
    }
  setItems(eventList2);
setCompInfo(compData.CompDetails);
  dispatch({type:'loaded'});
 
     }



return (
    <div >
       <ParticipantTable list={List} CompId={id} compInfo={compInfo} AdminToken = {props.AdminToken} CompName={id}/>
    </div>
);
};
export default CompetitionPage;