import mycaImage from "./MYCA_LOGO.jpg";

import './bannerStyle.css';
const Banner = (props) =>{
    return (
        <div >
 
 <div>{props.children}</div>

</div>
    );

    
}
export default Banner;