import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import {Amplify,Auth} from 'aws-amplify';
import {uuidv4} from 'uuid';
import './App.css';
import { useSelector,useDispatch } from 'react-redux';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Banner from './Component/Header/header';
import DashboardContent from './pages/dashboard/Dashboard';
import CompetitionList from './pages/Organizer/CompetitionList/CompetitionList';
import HomePage from './pages/homePage/homePage';
import AuthCheckPage from './pages/LoginControl/AuthCheck';
import CompetitionPage from './pages/competitionPage/CompetitionPage';
import EditParticipantForm from './pages/competitionPage/EditParticipantForm';
import config from './config.json';
import CreateCompetitionForm from './pages/CreateCompetitionPage/CreateComp';
import UpdateCompetitionPage from './pages/updateCompetition/UpdateComp';
import LoadingOverlay from './Component/loadingPage/LoadingPage';
import ResultAnalyzerPage from "./pages/ResultAnalyzer/ResultAnalyzerPage";
import StaffListPage from "./pages/staffRegistration/StaffList/Page.js";
import ResendCompTicketPage from "./pages/ResendCompTicket/Page";
import MerchantFormListPage from "./pages/MerchantForm/Page";
import TeamMYListPage from "./pages/staffRegistration/TeamMYList/Page";

function App() {

  const [isLoggedIn, setisLoggedIn] = useState(false);
  const dispatch = useDispatch();
    const [username,setUsername] = useState("");
    const [AdminToken,setAdminToken] = useState("");
    const userpool = config.UserPool;
    const adminUserpool = config.AdminUserPool;
    const AWSRegion = config.AWSREGION;
  let AdminTokenConst;
  const isLoading = useSelector(state => state.isLoading);
    Amplify.configure({
      Auth: {
        userPoolId: adminUserpool.id, //UserPool ID
        region: AWSRegion,
        userPoolWebClientId: adminUserpool.webclient //WebClientId
      }
    });
  const GetloginStatus = async () => {
    dispatch({ type: 'loading' });
    try {
      // get login status for myca aws user account

      const AuthInfo = await Auth.currentAuthenticatedUser();
      const userAuth =await Auth.currentSession();
      setAdminToken(userAuth.idToken.jwtToken);
      // save token to local storage
      localStorage.setItem('MYCAAdminToken',userAuth.idToken.jwtToken);

      //console.log(AuthInfo.username);


     if (AuthInfo.username === undefined) {
      setisLoggedIn(false);
     }
     else{

      setisLoggedIn(true);
      setUsername(AuthInfo.attributes.given_name);
     }
           // check if session expired
            Auth.currentSession().then((response)=>{
            }).catch((error)=>{
              console.log(error);
              setisLoggedIn(false);
            });
            
  } catch {
    setisLoggedIn(false);

    }
    dispatch({ type: 'loaded' });
  }
  useEffect(() => {

    GetloginStatus();

  },[]);

  return (
    <div className="App">

    <DashboardContent loginState={isLoggedIn}>
    <AuthCheckPage loginState={isLoggedIn}>
    <BrowserRouter>
    <Banner/>
<Routes>
<Route path="/" element={<CompetitionList />} />
<Route path="/competitionList" element={<CompetitionList />} />
<Route path="/competitionView/:id" element={<CompetitionPage />} AdminToken= {AdminTokenConst}/>
<Route path="/CreateCompetition" element={<CreateCompetitionForm />} AdminToken= {AdminTokenConst}/>
<Route path="/UpdateCompetition/:id" element={<UpdateCompetitionPage />} AdminToken= {AdminTokenConst}/>
<Route path="/EditParticipantInfo/:id" element={<EditParticipantForm />} AdminToken={AdminTokenConst} />
<Route path="/ResultAnalyzer" element={<ResultAnalyzerPage/>} AdminToken={AdminTokenConst}/>
<Route path="/StaffList/ac2024" element={<StaffListPage/>} AdminToken={AdminTokenConst}/>
    <Route path="/teamMYList/ac2024" element={<TeamMYListPage/>} AdminToken={AdminTokenConst}/>
    <Route path="/MerchantFormList/ac2024" element={<MerchantFormListPage/>} AdminToken={AdminTokenConst}/>
<Route path="/ResendCompTicketPage" element={<ResendCompTicketPage />} />
    </Routes>

          </BrowserRouter>

    </AuthCheckPage>
    </DashboardContent>
        <LoadingOverlay isLoading={isLoading} />
    </div>
  );
}

export default App;
